import React, { useState } from 'react';
import { format } from 'date-fns'

const WeatherResults = (props: any) => {
    // const formattedDate = format(props.datetime, 'dd/mm/yyyy');
    
    return (
        <div>
            <div className='space-me'>
                Results for {props.address}
            </div>
            <div className='space-me'>
                Feels like {props.feelslike} with a dew point of&nbsp;  
                {props.dew}&deg; and a {props.precip}&#37; chance of precipitation.
                The wind is out of {props.winddir}&deg; 
                at {props.windspeed} mph
            </div>
            <div className='space-me'>
                <label>Latest weather recorded</label>
                <p>
                   
                </p>
            </div>
            <div>
                <label>Current Conditions: {props.conditions}</label>
            <p>
                {props.description}
            </p>
            </div>
        </div>
    )
}

export default WeatherResults;