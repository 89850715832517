export class CurrentConditionsResponse {
    cloudcover = 0;
    conditions = "";
    datetime = "";
    dew = 0;
    feelslike = 0;
    humidity = 0;
    icon = "";
    moonphase = 0;
    precip = 0;
    pressure = 0;
    sunrise = "";
    temp = 0;
    windspeed = 0;
    winddir = 0;
    sunset = 0;
}