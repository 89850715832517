import React from 'react';

const Header = () => {
    return (
        <nav className="navbar navbar-dark bg-primary">
            <div className="px-4 navbar-brand">
                <img src="/logowb.jpg" className='image-logo' alt="" />
            </div>
            <div className="navbar-collapse collapse" id="navbarNav">
            </div>
        </nav>
    )
 }

export default Header;