import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';

import { WeatherResponse } from '../models/WeatherResponse';
import WeatherResults from './WeatherResults';
import ErrorData from './ErrorData';
import Forecast from './Forecast';

const Weather = () => {
    const [weatherData, setWeatherData] = useState(new WeatherResponse());
    const [error, setError] = useState("");
    const [icon, setIcon] = useState("");
    const [zip, setZip] = useState("");

    const handleChange = (e: any) => {
      e.preventDefault();
      setZip(e.target.value);
      setError("");
    }

    const submit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      if (!zip) {
        setError("Please enter a zip code");
        setWeatherData(new WeatherResponse())
        return;
      }

      const url = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${zip}?key=59JUEM6MR5JKKYAZ6YF9M9KNC`;
  
      await axios.get<WeatherResponse>(url)
      .then(res => {
        setWeatherData(res.data);
        console.log(`${res.data.currentConditions.icon.trim()}.svg`);
        setIcon(`/${res.data.currentConditions.icon.trim()}.png`);
        
        console.log(res.data);
      })
      .catch(err => {
        setError("Error looking up " + zip);
        console.log("Error looking up " + zip);
      });
    };
    return (
      <div>
        <div>
          <div>
            <form>
                <div className='row'>
                    <div className='col col-xs-12 col-sm-3'>
                        <input 
                            placeholder='zip code'
                            type='text' 
                            className='form-control zip-text'
                            value={zip} onChange={handleChange}
                            name='zip'></input>
                    </div>
                    <div className='col col-xs-12 col-md-9'>
                        <button className='float-left btn btn-primary' 
                            onClick={submit}>Go</button>
                    </div>
                </div>
            </form>
          </div>
          {error &&  (
            <ErrorData error={error} />
          )}
          {!error && weatherData.currentConditions.conditions !== "" &&  (
            <div className='row test-border'>
              <div className='col col-xs-12 col-sm-12 col-md-6'>
                <div className='space-me temp-text'>
                  <img src={icon} className='weather-icon' alt="Weather Icon" />&nbsp;
                  {weatherData.currentConditions.temp}&deg;
                </div>
                <div>
                  <WeatherResults 
                    address={weatherData.address}
                    feelslike={weatherData.currentConditions.feelslike}
                    dew={weatherData.currentConditions.dew}
                    precip={weatherData.currentConditions.precip}
                    winddir={weatherData.currentConditions.winddir}
                    windspeed={weatherData.currentConditions.windspeed}
                    datetime={weatherData.currentConditions.datetime}
                    conditions={weatherData.currentConditions.conditions} 
                    />
                </div>
              </div>
              <div className='col col-xs-12 col-sm-12 col-md-6'>
              <Forecast days={weatherData.days}  />
              </div>
            </div>
          )}

        </div>
      </div>
    );
  };

  export default Weather;
