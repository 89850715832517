import React from 'react';

const ErrorData = (props: any) => {
    return (
        <div>
        <div className='space-me'>
          Unable to retrieve data.  An error was detected
        </div>
        <div>
          {props.error}
        </div>
      </div>
    )
}
export default ErrorData;
