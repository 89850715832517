import React from 'react';
import { WeatherDayResponse } from '../models/WeatherDayResponse';

    const Forecast = (props: { days: WeatherDayResponse[]; }) => {
        const getIcon = (iconName: string) => {
            return (
                `/${iconName}.png`
            )}
        const getDate = (date: string) => {
            return (
                date
                // {new Intl.DateTimeFormat("en-US", {
                //     yearhttp://localhost:3000: "numeric",
                //     month: "long",
                //     day: "2-digit"
                //   }).format(date)}
            )
        }
    return (
        <div>
            <div className='header'>
                Forecast
            </div>
            <div>
            {props.days.map((day: WeatherDayResponse, index: number) => {
            return (
                <div key="{index}" className="row test-border forecast space-me">
                    <hr />
                    <div className='col col-md-6 forecast-icon center'>
                        <img src={getIcon(day.icon)} className='weather-icon' alt="Weather Icon" />&nbsp;
                        {day.tempmin}&deg;<br />
                        {day.tempmax}&deg;
                    </div>
                    <div className='col col-md-6'>
                        <div>{getDate(day.datetime)}</div>
                        <div>
                        <i>{day.conditions }</i>
                        </div>
                        <div>
                            {day.description}
                        </div>
                    </div>
                </div>
            );
        })}
            </div>
        </div>
    )
}

export default Forecast;