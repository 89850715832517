import { CurrentConditionsResponse } from "./CurrentConditionsResponse";
import { WeatherDayResponse } from "./WeatherDayResponse";

export class WeatherResponse {
    address = "";
    description = "";
    latitude = 0;
    longitude = 0;
    days: WeatherDayResponse[] = [];
    currentConditions: CurrentConditionsResponse = new CurrentConditionsResponse();
}