import React from 'react';
import './App.css';
import Weather from './components/Weather';
import Header from './components/Header';

function App() {
  return (
    <div>
      <Header />
      <div className="container">
        <header className="header">
          Welcome to WeatherBuddy
        </header>
        <div className="sub-header">
          Find the weather in your neck of the woods
        </div>
        <div className='pad-me-top'>
          <Weather />
        </div>
      </div>
    </div>
  );
}

export default App;
